<template>
	<b-card>
		<div class="list-view mb-1">
			<b-row class="m-1 top-header">
				<b-col md="6" class="d-flex align-items-center cursor-pointer">
					<div @click="goBack">
						<feather-icon icon="ArrowLeftIcon" size="24" />
					</div>
					<h3 class="ml-2 mb-0">{{ this.hatch_id }}</h3>
				</b-col>
			</b-row>
			<b-row class="justify-content-center m-2">
				<div class="d-flex">
					<div class="d-flex" style="position: relative">
						<b-card class="ml-2 pt-2" style="background-color: #d7f3e1">
							<div class="d-flex">
								<b-img
									:src="require('@/assets/images/timeline/product.svg')"
									style="height: 40px; width: 40px"
								/>
								<div class="ml-1">
									<p style="margin-bottom: 10px">
										Purchased From &nbsp;
										{{ dayInfo.sourceInfo ? dayInfo.sourceInfo.name : "N/A" }}
									</p>
									<p style="margin-bottom: 10px">
										<b-img
											:src="require('@/assets/images/timeline/date.svg')"
											style="height: 15px; width: 15px; margin-right: 10px"
										/>
										Date
										<span class="info-text">{{
											dayInfo.purchase_date ? dayInfo.purchase_date : "NA"
										}}</span>
									</p>
									<div class="d-flex">
										<div class="" style="margin-bottom: 10px">
											<span class="mr-1"
												><b-img
													:src="
														require('@/assets/images/timeline/location.svg')
													"
													style="height: 15px; width: 15px; margin-right: 10px"
													class=""
												/>
												{{
													dayInfo.sourceInfo ? dayInfo.sourceInfo.address : "NA"
												}}
												<!-- {{ this.dayInfo.sourceInfo.address || "NA" }} -->
											</span>
										</div>
									</div>
									<div class="d-flex" style="margin-bottom: 10px">
										<div class="mr-4">
											<span class="mr-1">
												<b-img
													:src="require('@/assets/images/timeline/product.svg')"
													style="height: 20px; width: 20px"
												/>
											</span>
											<span class=""
												>Egg Qty &nbsp; {{ dayInfo.egg_qty || "NA" }}</span
											>
										</div>
										<div class="mr-4">
											<span class="mr-1">
												<b-img
													:src="require('@/assets/images/timeline/product.svg')"
													style="height: 20px; width: 20px"
												/>
											</span>
											<span class="">
												Damaged &nbsp; {{ dayInfo.damaged }}</span
											>
										</div>
									</div>
								</div>
							</div>
						</b-card>
						<div class="arrow"></div>
					</div>
					<b-card class="ml-2">
						<div class="d-flex">
							<b-img
								:src="require('@/assets/images/timeline/product.svg')"
								style="height: 40px; width: 40px"
							/>
							<div class="ml-1">
								<p style="margin-bottom: 10px">
									Set up and run
									<!-- <span class="info-text">{{
                    dayTimeline.setter_machine_name
                  }}</span> -->
								</p>
								<p style="margin-bottom: 10px">
									<b-img
										:src="require('@/assets/images/timeline/date.svg')"
										style="height: 15px; width: 15px; margin-right: 8px"
									/>
									Date
									<span class="info-text">{{ startDate[0] }}</span>
								</p>
								<!-- <div class="d-flex">
                  <div class="mr-4">
                    <span class="mr-1"> Temperature </span>
                    <span class="mr-1">
                      <b-img
                        :src="
                          require('@/assets/images/svg/machine/temprature.svg')
                        "
                      />
                    </span>
                    <span class="head-text"
                      >10
                      <sup
                        style="
                          color: #f6667e;
                          font-size: 10px;
                          margin-right: 3px;
                        "
                        >0</sup
                      ><span class="red-text">c</span></span
                    >
                  </div>
                  <div>
                    <span class="mr-1">
                      <b-img
                        :src="
                          require('@/assets/images/svg/machine/humidity.svg')
                        "
                      />
                    </span>
                    <span class="info-text"
                      >20

                      <span style="font-size: 12px">%</span></span
                    >
                  </div>
                </div> -->
								<div class="d-flex" style="margin-bottom: 10px">
									<div class="mr-4">
										<span class="mr-1"> Average Weight </span>

										<span class="info-text">
											{{ dayTimeline.avg_weight }}gram
										</span>
									</div>
								</div>
								<div class="d-flex">
									<div class="success">
										Flock ID : &nbsp;{{ dayInfo.flock_id }}
									</div>
									<div class="info">
										<span> Batch ID : &nbsp;{{ dayInfo.batch_id }}</span>
									</div>
								</div>
							</div>
						</div>
					</b-card>
				</div>
			</b-row>

			<b-row class="p-1">
				<div
					class="col-6 d-flex mb-2 p-0"
					style="position: relative"
					v-for="(status, index) in timelineData.stage_status"
					:key="index"
				>
					<b-card class="ml-2">
						<div class="d-flex">
							<b-img
								:src="require(`@/assets/images/timeline/${getImage(index)}`)"
								style="height: 120px; width: 120px"
							/>

							<div class="ml-1">
								<div class="text-success font-weight-bold">
									Day - {{ status.day_count }}
								</div>
								<p style="margin-bottom: 10px">{{ status.msg }}</p>
								<div class="d-flex">
									<div class="mr-5">
										<span class="mr-1">
											<b-img
												:src="
													require('@/assets/images/svg/machine/temprature.svg')
												"
											/>
										</span>
										<span class="head-text">
											{{
												timelineData.incubation_temp_live[index]
													? timelineData.incubation_temp_live[index]
															.temp_celsius
													: 0
											}}
											<sup
												style="
													color: #f6667e;
													font-size: 10px;
													margin-right: 3px;
												"
												>0</sup
											><span class="red-text">c</span></span
										>
									</div>
									<div>
										<span class="mr-1"> </span>
										<span class="info-text">
											<span class="mr-1"
												><b-img
													:src="
														require('@/assets/images/svg/machine/humidity.svg')
													"
											/></span>
											{{
												timelineData.incubation_temp_live[index]
													? timelineData.incubation_temp_live[index].humidity
													: 0
											}}
											<span style="font-size: 12px">%</span></span
										>
									</div>
								</div>
								<div class="d-flex" style="margin-bottom: 10px">
									<div class="mr-4 mt-1">
										<span class="mr-1"> Average Weight </span>

										<span class="info-text"> {{ avg_weight }} gram</span>
									</div>
								</div>
							</div>
						</div>
					</b-card>
					<div class="arrow"></div>
				</div>
			</b-row>
		</div>
	</b-card>
</template>

<script>
import {
	BCard,
	BCardBody,
	BCardHeader,
	BCardTitle,
	BCardSubTitle,
	BBadge,
	BRow,
	BCol,
	BImg,
} from "bootstrap-vue";
import axiosIns from "@/libs/axios";

export default {
	components: {
		BCardHeader,
		BCard,
		BBadge,
		BCardBody,
		BCardTitle,
		BCardSubTitle,
		BRow,
		BCol,
		BImg,
	},
	data() {
		return {
			dataTimeline: {},
			hatch_id: null,
			hatchery_device_id: null,
			startDate: null,
			machine_id: null,
			hatchery_id: null,
			stage_status: [],
			incubation_temp_live: [],
			avg_weight: null,
			dayInfo: {
				sourceInfo: null,
				address: null,
				egg_qty: null,
				damaged: null,
				flock_id: null,
				batch_id: null,
				purchase_date: null,
			},
			msg: null,
			day_count: null,
			tem_live_data: null,
			temp_celsius: null,
			humidity: null,
			timelineData: [],
		};
	},
	created() {
		const hatch_id = this.$route.params.hatchId;
		this.hatchery_device_id = this.$route.params.hatchery_device_id;
		this.hatchery_id = this.$route.params.hatcheryId;
		this.machine_id = this.$route.params.machine_id;
		this.hatch_id = hatch_id;
		this.timeline();
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		timeline() {
			axiosIns
				.get(
					`web/hatcheries/${this.hatchery_id}/Machine/${this.machine_id}/setter-hatch/timeline?hatch_id=${this.hatch_id}`
				)
				.then((response) => {
					this.timelineData = response.data;
					this.stage_status = this.timelineData.stage_status;
					this.avg_weight = this.timelineData.day1.avg_weight;
					// this.timelineData.stage_status.forEach((item, index) => {
					//   // let msg = item.msg;
					//   // let day_count = item.day_count;

					//   let tem_live_data = timelineData.incubation_temp_live[index] || {};

					//   this.temp_celsius = tem_live_data.temp_celsius || 0;
					//   this.humidity = tem_live_data.humidity || 0;

					// });

					this.dayTimeline = response.data.day1;
					this.dayInfo.sourceInfo = response.data.day1.sourceInfo;
					this.incubation_temp_live = response.data.incubation_temp_live;
					this.dayInfo.egg_qty = response.data.day1.purchaseInfo.egg_qty;
					this.dayInfo.damaged = response.data.day1.purchaseInfo.damaged;
					this.dayInfo.flock_id = response.data.day1.purchaseInfo.flock_id;
					this.dayInfo.batch_id = response.data.day1.purchaseInfo.batch_id;
					this.dayInfo.purchase_date =
						response.data.day1.purchaseInfo.purchase_date;
					this.startDate = response.data.datePeriod;
					this.data = response.data;
				});
		},
		getImage(index) {
			const images = {
				1: "day-1.png",
				2: "day-1.png",
				3: "day-1.png",
				4: "day-4.png",
				5: "day-4.png",
				6: "day-6.png",
				7: "day-6.png",
				8: "day-8.png",
				9: "day-8.png",
				10: "day-10.png",
				11: "day-10.png",
				12: "day-12.png",
				13: "day-12.png",
				13: "day-12.png",
				14: "day-12.png",
				15: "day-12.png",
				16: "day-12.png",
				17: "day-12.png",
				18: "day-18.png",
				19: "day-18.png",
				20: "day-18.png",
				21: "day-21.png",
			};

			return images[index + 1] || "day-12.png";
		},
	},
};
</script>

<style lang="scss" scoped>
.list-view {
	background-color: #fafafa;
	box-shadow: 0px 3px 6px #38c06c33;
	height: 100%;
	padding: 1rem 1rem;
	margin: auto;
}
.top-header {
	border-bottom: 1px solid #38c06c;
	padding: 1rem 0rem;
}
.svg-img {
	height: 100px;
	width: 100px;
}
.timeline-card {
	width: 350px;
	margin-bottom: 2rem;
}
.arrow {
	margin-top: 15%;
	margin-left: 15px;
	border: 1px solid #00b866;
	background-color: #00b866;
	height: 1px;
	width: 120px;
}
.info-text {
	color: #00b866;
	font-size: 12px;
}
.success {
	background-color: #d7f3e1;
	font-size: 11px;
	color: #38c06c;
	display: flex;
	align-items: center;
	margin-right: 1rem;
	width: 150px;
	padding: 15px 15px;
	height: 33px;
	border-radius: 16px;
	justify-content: center;
}
.info {
	background-color: #5dadfd33;
	font-size: 11px;
	color: #5dadfd;
	display: flex;
	align-items: center;
	width: 150px;
	padding: 1rem 1rem;
	height: 33px;
	justify-content: center;
	border-radius: 16px;
}
</style>
